import $ from 'jquery';

const popup = $('.AddedToCartPopup');

const showPopup = (content) => {
    popup.find('.AddedToCartPopup__content').html(content);
    popup.addClass('open');
};
const hidePopup = () => {
    popup.find('.AddedToCartPopup__content').html();
    popup.removeClass('open');
};

const fetchLatestProductData = () => {
    $.ajax({
        type: 'GET',
        url: jsData.ajaxUrl,
        data: {
            action: 'kreacja_ajax_latest_atc',
        },
        success: (response) => {
            if (response.success) {
                showPopup(response.data.content);
            }
        },
    });
};

$(document.body).on('added_to_cart', fetchLatestProductData);

$('.AddedToCartPopup__continue, .AddedToCartPopup__closeButton ').on('click', hidePopup);
