import 'swiper/swiper-bundle.css';
import Swiper from 'swiper';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';

const swiper = new Swiper('.opinions-slider', {
    modules: [Navigation, Autoplay, Pagination],
    speed: 400,
    spaceBetween: 20,
    centeredSlides: true,
    slidesPerView: 'auto',
    loop: true,
    navigation: {
        nextEl: '.Opinions__arrow--right',
        prevEl: '.Opinions__arrow--left',
    },
    // autoplay: {
    //     delay: 5000,
    // },
    pagination: {
        el: '.Opinions__pagination',
        clickable: true,
    },
});
