import $ from 'jquery';

let isToggled = false;

const changeText = () => {
    if (isToggled) {
        $('.category-list-button-text').text('Pokaż mniej');
    } else {
        $('.category-list-button-text').text('Pokaż więcej');
    }
};

$('.category-list-button').on('click', function () {
    isToggled = !isToggled;
    $('.category-list').toggleClass('category-list--active');
    $('.category-list-button').toggleClass('category-list-button--active');
    changeText();
});

$('.category-list-title').on('click', function () {
    if (window.innerWidth < 768) {
        isToggled = !isToggled;
        $('.category-list').toggleClass('category-list--active');
        $('.category-list-title').toggleClass('category-list-title--active');
        changeText();
    }
});
