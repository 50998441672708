const container = document.querySelector('#my-account-dropdown-container');

const buttons = document.querySelectorAll('.my-account-dropdown-btn');

const toggleDropdown = () => {
    container.classList.toggle('active');
};

buttons.forEach((button) => {
    button?.addEventListener('click', toggleDropdown);
});
